import axios from '@/plugins/axios.js'

// 新增
export const strategyAdd = (params) => axios.post('/strategy/add', params)

// 查询
export const strategyQuery = (params) => axios.get('/strategy/query', { params })

// 删除
export const strategyRemoveBatch = (data) => axios.delete('/strategy/removeBatch', { data })

// 搜索
export const strategySearch = (params) => axios.post('/strategy/search', params)

// 编辑
export const strategyUpdate = (params) => axios.post('/strategy/update', params)
