<template>
  <div class="page">
    <div class="page-main">
      <div class="steps">
        <kxjl-steps :active="active" :steps="steps" />
      </div>
      <!-- Set basic information -->
      <div v-if="active == 0">
        <el-form class="page-card"
          ref="form0"
          :model="form"
          :rules="rules"
          label-width="auto"
        >
          <el-form-item :label="$t('alarmStrategy.strategy')" prop="strategyName">
            <el-input v-model="form.strategyName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('alarmStrategy.alarmStrategy')" prop="strategyMode">
            <el-checkbox-group v-model="strategyModeCheck">
              <el-checkbox :label="1">{{ strategyMode[1] }}</el-checkbox>
              <el-checkbox :label="2">{{ strategyMode[2] }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('alarmStrategy.notificationFrequency')" prop="frequency">
            <el-radio-group v-model="form.frequency">
              <el-radio :label="+value" v-for="(label, value) in strategyFrequency" :key="value">
                {{ label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('alarmStrategy.status')">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"> </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <!-- Alarm Receiver -->
      <div v-if="active == 1">
        <el-form class="page-card-2" ref="form1" :model="form" :rules="rules">
          <el-form-item prop="userIds">
            <transfer-user v-model="form.userIds"></transfer-user>
          </el-form-item>
        </el-form>
      </div>
      <!-- Alarm content -->
      <div v-if="active == 2">
        <el-form class="page-card" ref="form2" :model="form" :rules="rules">
          <el-form-item prop="contents">
            <alarm-content v-model="form.contents"></alarm-content>
          </el-form-item>
        </el-form>
      </div>
      <!-- Devices -->
      <div v-if="active == 3">
        <el-form class="page-card-2" ref="form3" :model="form" :rules="rules">
          <el-form-item :label="$t('alarmStrategy.deviceType')">
            <el-radio-group v-model="form.deviceType" @change="changeDeviceType">
              <el-radio :label="+value" v-for="(label, value) in strategyDeviceType" :key="value">
                {{ label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <select-site
              v-if="form.deviceType == 2"
              v-model="form.deviceIds"
              checkboxShow
              checkStrictly
            />
          </el-form-item>
          <el-form-item>
            <transfer-group
              v-if="form.deviceType == 3"
              v-model="form.deviceIds"
              :toolsDisplay="{ groupShow: true }"
            ></transfer-group>
          </el-form-item>
          <el-form-item>
            <transfer-device
              v-if="form.deviceType == 4"
              v-model="form.deviceIds"
              :toolsDisplay="{ siteShow: true, modelShow: true, keywordsShow: true }"
            ></transfer-device>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer-btns">
        <el-button class="footer-btn" type="primary" plain @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
        <el-button class="footer-btn" v-if="active != 0" type="primary" @click="lastStep">{{ $t('lastStep') }}</el-button>
        <el-button class="footer-btn" v-if="active == 3" type="primary" @click="submit()">{{ $t('save') }}</el-button>
        <el-button class="footer-btn" v-else type="primary" @click="nextStep">{{ $t('nextStep') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { strategyQuery, strategyAdd, strategyUpdate } from '@/api/alarmStrategy'
import { SelectSite, TransferUser, TransferGroup, TransferDevice } from '@/components'
import AlarmContent from './alarm-content'
import KxjlSteps from '../../../../components/kxjl-steps.vue'

export default {
  name: 'AlarmStrategyDetails',
  components: { SelectSite, TransferUser, TransferGroup, TransferDevice, AlarmContent, KxjlSteps },
  data() {
    return {
      active: 0,
      steps: [
        this.$t('strategySteps.setBasicInformation'),
        this.$t('strategySteps.alarmReceiver'),
        this.$t('strategySteps.alarmContent'),
        this.$t('strategySteps.devices')
      ],
      form: {
        strategyName: '',
        strategyMode: 3,
        frequency: 1,
        status: 1,
        userIds: [],
        contents: [],
        deviceType: 1,
        deviceIds: []
      },
      rules: {
        strategyName: {
          required: true,
          message: this.$t('alarmStrategy.strategyNameIsNull')
        },
        strategyMode: {
          required: true,
          message: this.$t('alarmStrategy.strategyModeIsNull')
        },
        frequency: {
          required: true,
        },
        // userIds: {
        //   required: true,
        // },
        contents: {
          required: true,
          message: this.$t('alarmStrategy.contentsIsNull')
        },
      },
    }
  },
  computed: {
    ...mapGetters('dist', ['strategyMode', 'strategyFrequency', 'strategyDeviceType']),
    strategyModeCheck: {
      get() {
        console.log('get mode', this.form.strategyMode)
        return [[],[1],[2],[1,2]][this.form.strategyMode]
      },
      set(val) {
        console.log('set strategy: len: ', val.length)
        if (val.length) {
          if (val.length > 1) {
            this.form.strategyMode = val[0] + val[1]
            console.log('strategy 2:', val[0], val[1])
          } else {
            this.form.strategyMode = val[0]
            console.log('strategy 1:', val[0])
          }
        } else {
          this.form.strategyMode = 0
          this.$message.warning(this.$t('alarmStrategy.contentsIsNull'))
        }
      },
    }
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
  },
  methods: {
    changeDeviceType() {
      this.form.deviceIds = []
    },
    getData(id) {
      strategyQuery({ id })
        .then(({ data }) => {
          const {
            id,
            strategyName,
            strategyMode,
            frequency,
            status,
            receiver,
            contents,
            deviceType,
            devices,
            siteIds
          } = data.result.rows[0]
          this.form.id = id
          this.form.strategyName = strategyName
          this.form.strategyMode = strategyMode
          this.form.frequency = frequency
          this.form.status = status
          this.form.userIds = receiver.map((e) => { return { id: e.userId, userName: e.userName, email: e.email } })
          this.form.contents = contents
          this.form.deviceType = deviceType
          this.form.deviceIds = [[], [], siteIds, [], devices][deviceType]
        })
        .catch(() => {})
    },
    lastStep() {
      this.$refs['form' + this.active].clearValidate()
      this.active--
    },
    nextStep() {
      this.$refs['form' + this.active].validate((valid) => {
        if (this.active == 1 && this.form.userIds.length == 0) {
          this.$message.error(this.$t('alarmStrategy.userIsNull'))
        } else if (this.active == 0 && this.form.strategyMode == 0) {
          this.$message.error(this.$t('alarmStrategy.contentsIsNull'))
        } else if (valid) {
          this.active++
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submit() {
      if(this.form.deviceType != 1 && !this.form.deviceIds.length) {
        this.$message.error(this.$t('alarmStrategy.deviceIsNull'))
        return 
      }
     
      let params = {
        strategyName: this.form.strategyName,
        strategyMode: this.form.strategyMode,
        frequency: this.form.frequency,
        status: this.form.status,
        userIds: this.form.userIds.map((e) => e.id),
        contents: this.form.contents,
        deviceType: this.form.deviceType,
        deviceIds: this.form.deviceIds.map((e) => typeof e == 'string' ? e : e.id),
      }
      this.$route.query.id && (params.id = this.form.id)
      const submitApi = this.$route.query.id ? strategyUpdate : strategyAdd
      submitApi(params)
        .then(({ data }) => {
          this.$message.success(data.message)
          this.$router.go(-1)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 0;
  .page-card {
    padding: 0 140px;
  }
  .page-card-2 {
    padding: 0 40px;
  }
  .footer-btns {
    padding: 39px 140px;
  }
}
</style>