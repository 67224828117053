<style lang="scss" scoped>
.table {
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-collapse: separate;
  border-spacing: 0;      /*设置列、行间距*/
  tr {
    td {
      padding: 40px 30px;
      border-bottom: 1px solid #F6F6F6;
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
</style>
<template>
  <table class="table">
    <tr>
      <td>
        <el-checkbox
          class="total"
          :indeterminate="criticalIsIndeterminate"
          v-model="criticalCheckAll"
          @change="criticalCheckAllChange"
          >{{ alarmLevel[1] }}</el-checkbox
        >
      </td>
      <td>
        <el-checkbox-group v-model="criticalChecked" @change="criticalCheckedCitiesChange">
          <el-checkbox class="item" :label="item" v-for="item in criticalCheck" :key="item">{{
            alarmType[item]
          }}</el-checkbox>
        </el-checkbox-group>
      </td>
    </tr>
    <tr>
      <td>
        <el-checkbox
          class="total"
          :indeterminate="majorIsIndeterminate"
          v-model="majorCheckAll"
          @change="majorCheckAllChange"
          >{{ alarmLevel[2] }}</el-checkbox
        >
      </td>
      <td>
        <el-checkbox-group v-model="majorChecked" @change="majorCheckedCitiesChange">
          <el-checkbox class="item" :label="item" v-for="item in majorCheck" :key="item">{{
            alarmType[item]
          }}</el-checkbox>
        </el-checkbox-group>
      </td>
    </tr>
    <tr>
      <td>
        <el-checkbox
          class="total"
          :indeterminate="minorIsIndeterminate"
          v-model="minorCheckAll"
          @change="minorCheckAllChange"
          >{{ alarmLevel[3] }}</el-checkbox
        >
      </td>
      <td>
        <el-checkbox-group v-model="minorChecked" @change="minorCheckedCitiesChange">
          <el-checkbox class="item" :label="item" v-for="item in minorCheck" :key="item">{{
            alarmType[item]
          }}</el-checkbox>
        </el-checkbox-group>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'alarm-content',
  model: {
    prop: 'contents',
    event: 'change',
  },
  props: {
    contents: Array,
  },
  data() {
    return {
      // critical
      criticalIsIndeterminate: false,
      criticalCheckAll: false,
      criticalChecked: [],
      criticalCheck: [1, 2, 4],
      // major
      majorIsIndeterminate: false,
      majorCheckAll: false,
      majorChecked: [],
      // majorCheck: [3, 8],
      // minor
      minorIsIndeterminate: false,
      minorCheckAll: false,
      minorChecked: [],
      minorCheck: [6],
      // minorCheck: [5, 7, 6],
    }
  },
  computed: {
    ...mapState('session', ['isLAN']),
    ...mapGetters('dist', ['alarmType', 'alarmLevel']),
    majorCheck() {
      return this.isLAN ? [3, 8, 10] : [3, 8]
    }
  },
  watch: {
    contents(value) {
      this.setData(value)
    },
  },
  created() {
    this.setData(this.contents)
  },
  methods: {
    getData() {
      let arr = []
      this.criticalChecked.forEach((e) => {
        arr.push({
          level: 1,
          type: e,
        })
      })
      this.majorChecked.forEach((e) => {
        arr.push({
          level: 2,
          type: e,
        })
      })
      this.minorChecked.forEach((e) => {
        arr.push({
          level: 3,
          type: e,
        })
      })
      return arr
    },
    setData(value) {
      this.criticalChecked = []
      this.majorChecked = []
      this.minorChecked = []
      value.forEach((item) => {
        switch (item.level) {
          case 1:
            this.criticalChecked.push(item.type)
            break
          case 2:
            this.majorChecked.push(item.type)
            break
          case 3:
            this.minorChecked.push(item.type)
            break
        }
      })
      this.criticalCheckedCitiesChange(this.criticalChecked, false)
      this.majorCheckedCitiesChange(this.majorChecked, false)
      this.minorCheckedCitiesChange(this.minorChecked, false)
    },
    // critical
    criticalCheckAllChange(value, type = true) {
      this.criticalChecked = value ? this.criticalCheck : []
      this.criticalIsIndeterminate = false
      if (type) this.$emit('change', this.getData())
    },
    criticalCheckedCitiesChange(value, type = true) {
      let checkedCount = value.length
      this.criticalCheckAll = checkedCount === this.criticalCheck.length
      this.criticalIsIndeterminate = checkedCount > 0 && checkedCount < this.criticalCheck.length
      if (type) this.$emit('change', this.getData())
    },
    // major
    majorCheckAllChange(value, type = true) {
      this.majorChecked = value ? this.majorCheck : []
      this.majorIsIndeterminate = false
      if (type) this.$emit('change', this.getData())
    },
    majorCheckedCitiesChange(value, type = true) {
      let checkedCount = value.length
      this.majorCheckAll = checkedCount === this.majorCheck.length
      this.majorIsIndeterminate = checkedCount > 0 && checkedCount < this.majorCheck.length
      if (type) this.$emit('change', this.getData())
    },
    // minor
    minorCheckAllChange(value, type = true) {
      this.minorChecked = value ? this.minorCheck : []
      this.minorIsIndeterminate = false
      if (type) this.$emit('change', this.getData())
    },
    minorCheckedCitiesChange(value, type = true) {
      let checkedCount = value.length
      this.minorCheckAll = checkedCount === this.minorCheck.length
      this.minorIsIndeterminate = checkedCount > 0 && checkedCount < this.minorCheck.length
      if (type) this.$emit('change', this.getData())
    },
  },
}
</script>

<style lang="scss" scoped>
.total {
  ::v-deep .el-checkbox__label {
    font-size: 20px;
    font-weight: bold;
  }
}
.item {
  ::v-deep .el-checkbox__label {
    font-size: 18px;
  }
}
</style>
